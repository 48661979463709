import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import {
  POST_ICONS_COLOR,
  POST_PLACEHOLDER_COLOR,
  POST_TEXT_COLOR,
} from '../consts';

export type ICommentsStylesParams = {
  commentsReactionsCounterColor: IStyleParam<StyleParamType.Color>;
  commentsReactionsCounterFont: IStyleParam<StyleParamType.Font>;
  commentsCommentContentColor: IStyleParam<StyleParamType.Color>;
  commentsCommentContentFont: IStyleParam<StyleParamType.Font>;
  commentsReplyColor: IStyleParam<StyleParamType.Color>;
  commentsReplyFont: IStyleParam<StyleParamType.Font>;
  commentsUserNameColor: IStyleParam<StyleParamType.Color>;
  commentsUserNameFont: IStyleParam<StyleParamType.Font>;
  commentsTimeStampColor: IStyleParam<StyleParamType.Color>;
  commentsTimeStampFont: IStyleParam<StyleParamType.Font>;
  commentsPlaceholderColor: IStyleParam<StyleParamType.Color>;
  commentsPlaceholderFont: IStyleParam<StyleParamType.Font>;
  commentsShowMoreCommentsLink: IStyleParam<StyleParamType.Color>;
  commentsShowMoreRepliesLink: IStyleParam<StyleParamType.Color>;
  // TODO:
  // --- start: is not using in central feed, why? ---
  commentsBoxBorderColor: IStyleParam<StyleParamType.Color>;
  commentsBoxBorderWidth: IStyleParam<StyleParamType.Number>;
  commentsMoreActionIcon: IStyleParam<StyleParamType.Color>;
  commentsLike: IStyleParam<StyleParamType.Color>;
  commentsRceIcons: IStyleParam<StyleParamType.Color>;
  commentsApplicationBackground: IStyleParam<StyleParamType.Color>;
  // --- end: is not using in central feed, why? ---
};

export const commentsStylesParams: ICommentsStylesParams = {
  commentsReactionsCounterColor: {
    key: 'commentsReactionsCounterColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsReactionsCounterFont: {
    key: 'commentsReactionsCounterFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsCommentContentColor: {
    key: 'commentsCommentContent',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsCommentContentFont: {
    key: 'commentsCommentContent',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  commentsReplyColor: {
    key: 'commentsReply',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsReplyFont: {
    key: 'commentsReply',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  commentsUserNameColor: {
    key: 'commentsUserName',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsUserNameFont: {
    key: 'commentsUserName',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  commentsTimeStampColor: {
    key: 'commentsTimeStamp',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsTimeStampFont: {
    key: 'commentsTimeStamp',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsPlaceholderColor: {
    key: 'commentsPlaceholder',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_PLACEHOLDER_COLOR),
  },
  commentsPlaceholderFont: {
    key: 'commentsPlaceholder',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 14,
    }),
  },
  commentsShowMoreCommentsLink: {
    key: 'commentsShowMoreCommentsLink',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsShowMoreRepliesLink: {
    key: 'commentsShowMoreRepliesLink',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_TEXT_COLOR),
  },
  commentsApplicationBackground: {
    key: 'commentsApplicationBackground',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-3'),
  },
  commentsBoxBorderColor: {
    key: 'commentsBoxBorder',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.6),
  },
  commentsBoxBorderWidth: {
    key: 'commentsBoxBorder',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },

  commentsMoreActionIcon: {
    key: 'commentsMoreActionIcon',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentsLike: {
    key: 'commentsLike',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  commentsRceIcons: {
    key: 'commentsRceIcons',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam(POST_ICONS_COLOR),
  },
};
