import React from 'react';
import ReactDOM from 'react-dom';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { usePortalContainer } from './hooks';

interface IPortalProps {
  disable?: boolean;
  children: React.ReactNode;
}

export function Portal(props: IPortalProps) {
  const { isSSR } = useEnvironment();
  const container = usePortalContainer();

  if (props.disable) {
    return <>{props.children}</>;
  }

  if (isSSR) {
    return null;
  }

  return ReactDOM.createPortal(props.children, container as HTMLElement);
}

Portal.displayName = 'wui/Portal';
