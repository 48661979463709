import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import type { Event as IEvent } from '@wix/ambassador-events-v1-event/types';

import { Box } from 'wui/Box';
import { Card } from 'wui/Card';
import { Button } from 'wui/Button';
import { Tooltip } from 'wui/Tooltip';
import { Typography } from 'wui/Typography';
import { CardContent } from 'wui/CardContent';
import { CardActions } from 'wui/CardActions';
import { CardMedia, CardMediaPlaceholder } from 'wui/CardMedia';

interface IProps {
  event: IEvent;
  groupId: string;
}

export function Event(props: IProps) {
  const { event } = props;
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();

  const url = event.eventPageUrl
    ? `${event.eventPageUrl.base}${event.eventPageUrl.path}`
    : undefined;

  return (
    <Card
      gap="SP0"
      padding="SP0"
      sideBorders={!isMobile}
      direction={isMobile ? 'vertical' : 'horizontal'}
    >
      <Box flex={false} width={isMobile ? '100%' : '70%'} height={240}>
        {event.mainImage ? (
          <CardMedia
            fluid
            stretchImage
            resize="cover"
            src={event.mainImage.id as string}
            alt={event.mainImage.altText as string}
            sourceHeight={event.mainImage.height as number}
            sourceWidth={event.mainImage.width as number}
          />
        ) : (
          <CardMediaPlaceholder />
        )}
      </Box>
      <Box width="100%" padding="SP5 0" direction="vertical">
        <CardContent gap="SP2" height="100%" align="center">
          <Typography align="center" variant="h2-20">
            {props.event.title}
          </Typography>
          <Box direction="vertical" align="center">
            <Typography secondary variant="p2-14" align="center">
              {props.event.scheduling?.formatted}
            </Typography>
            <Typography secondary variant="p2-14" align="center">
              {props.event.location?.address || props.event.location?.name}
            </Typography>
          </Box>
          <Typography>{props.event.description}</Typography>
        </CardContent>
        <CardActions align="center">
          <Tooltip
            content={url ? undefined : t('groups-web.events.setup-warning')}
          >
            <Button disabled={!url} as="a" href={url} target="_blank">
              {t('groups-web.events.rsvp')}
            </Button>
          </Tooltip>
        </CardActions>
      </Box>
    </Card>
  );
}

Event.displayName = 'Event';
