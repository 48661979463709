import React from 'react';
import cls from 'classnames';
import { filterXSS } from 'xss';
import { useEnvironment } from '@wix/yoshi-flow-editor';

type IProps = {
  /**
   * name of the css variable, which should be defined in cssVars files (e.g.: src/settings/group/cssVars.ts)
   **/
  cssVarName: string;

  /**
   * here legacy settingParameter "value" should be passed to keep backward compatibility
   * @default false
   */
  legacyFallback?: boolean;

  /**
   * force show the children
   */
  forceShow?: boolean;

  /**
   * css property which will be used to set the value of the css variable.
   * @default 'display'
   */
  cssProperty?: 'display' | 'visibility';

  /**
   * children should be a single element that supports className prop
   */
  children: React.ReactElement<{ className?: string }>;
};

export const Wire = React.forwardRef<unknown, IProps>((props, ref) => {
  const {
    cssVarName,
    forceShow,
    children,
    cssProperty,
    legacyFallback,
    ...rest
  } = props;

  const { isCssPerBreakpoint } = useEnvironment();

  if (!isCssPerBreakpoint) {
    if (legacyFallback) {
      return React.cloneElement(children, {
        ref,
        ...children.props,
        ...rest,
      });
    }

    return null;
  }

  const className = `${cssVarName}--wired`;

  return (
    <>
      {!forceShow && (
        <style
          dangerouslySetInnerHTML={{
            __html: filterXSS(
              `.${className} { ${cssProperty}: var(--${cssVarName}); }`,
            ),
          }}
        />
      )}

      {React.isValidElement(children) ? (
        React.cloneElement(children, {
          ref,
          ...children.props,
          ...rest,
          className: cls(className, children.props.className),
        })
      ) : (
        <div className={className}>{children}</div>
      )}
    </>
  );
});

Wire.displayName = 'wui/Wire';
Wire.defaultProps = {
  cssProperty: 'display',
};
