import { partition } from 'lodash';
import {
  Event,
  EventStatus,
  SiteUrl,
} from '@wix/ambassador-events-v1-event/types';

export function filterEvents(
  events: Event[],
): [upcoming: Event[], past: Event[]] {
  const [upcoming, past] = partition(events, (event) =>
    [EventStatus.STARTED, EventStatus.SCHEDULED].includes(
      event.status as EventStatus,
    ),
  );

  return [upcoming || [], past || []];
}

export function getEventPage(eventPageUrl: SiteUrl | undefined) {
  return eventPageUrl ? `${eventPageUrl.base}${eventPageUrl.path}` : undefined;
}
