import React from 'react';
import { useSelector } from 'react-redux';

import type { IRootState } from 'store/types';
import { useController } from 'common/context/controller';

import { RouterContext } from '../context';

export function useRouter() {
  const router = React.useContext(RouterContext);
  const { router$ } = useController();
  const states = useSelector(
    (state: IRootState) => state.application.router.states,
  );

  return {
    states,

    /**
     * Directly calls router in controller
     */
    go: router$.go,

    /**
     * Directly calls router in controller
     */
    reload: router$.reload,

    /**
     * Please note that state params can not be inherited from the current state
     * since the router in react is not aware of the current state.
     */
    href: router.stateService.href,
  };
}
