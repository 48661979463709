import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  selectCanApproveMembers,
  selectGroup,
  selectMembersWithCountLabel,
} from 'store/selectors';
import { groupsSettingsParams } from 'settings/groups';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import { UISref } from 'router/react';

import { Wire } from 'wui/Wire';
import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';

import { getGroupPrivacyLabel } from './helpers';
import { GROUP_INFO_MEMBERS_COUNT } from './dataHooks';

import classes from './GroupInfo.scss';

interface IGroupInfoProps
  extends Omit<React.ComponentProps<typeof Stack>, 'children'> {
  wired?: boolean;
  groupId: string;
  misc?: boolean;
}

export function GroupInfo({
  groupId,
  misc,
  wired,
  className,
  ...rest
}: IGroupInfoProps) {
  const settings = useSettings();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const group = useSelector(selectGroup(groupId));
  const memberLabel = useSelector(selectMembersWithCountLabel(groupId));
  const canApproveMembers = useSelector(selectCanApproveMembers(groupId));

  const privacy = getGroupPrivacyLabel(group.privacyStatus);

  const pendingMembersCount = group.pendingMembersCount;

  if (pendingMembersCount && canApproveMembers && misc) {
    return (
      <UISref
        state="group.members"
        params={{ slug: group.slug, expandJoinedRequests: true }}
      >
        <TextButton as="a">
          {t('groups-web.group-list.pending-members.count_icu', {
            count: pendingMembersCount,
          })}
        </TextButton>
      </UISref>
    );
  }

  return (
    <Stack
      truncate
      className={cls(classes.root, {
        [classes.wired]: wired,
        [classes.mobile]: isMobile,
      })}
      separator={<span className={classes.separator}>&middot;</span>}
      {...rest}
    >
      <Wire
        cssVarName="showGroupType--inline"
        legacyFallback={settings.get(
          groupsListWidgetSettingsParams.showGroupType,
        )}
      >
        <span>{t(privacy)}</span>
      </Wire>

      <Wire
        cssVarName="showMemberCount--inline"
        legacyFallback={settings.get(groupsSettingsParams.showMemberCount)}
      >
        <span data-hook={GROUP_INFO_MEMBERS_COUNT}>
          {memberLabel.isCustom
            ? `${group.membersCount ?? 0} ${memberLabel.label}`
            : t(memberLabel.label, {
                formattedCount: group.membersCount ?? 0,
              })}
        </span>
      </Wire>

      <Show if={!!(group.pendingMembersCount && canApproveMembers)}>
        <UISref
          state="group.members"
          params={{ slug: group.slug, expandJoinedRequests: true }}
        >
          <TextButton as="a">
            {t('groups-web.group-list.pending-members.count_icu', {
              count: pendingMembersCount ?? 0,
            })}
          </TextButton>
        </UISref>
      </Show>
    </Stack>
  );
}

GroupInfo.displayName = 'GroupInfo';
