import {
  IStyleParam,
  StyleParamType,
  wixColorParam,
  wixFontParam,
} from '@wix/tpa-settings';

import { DEFAULT_GRID_CARD_SPACING, FontTheme } from '../consts';
import { doNotTransformSettingsKey, transformSettingsKey } from '../helpers';

export type IGroupsStylesParams = {
  /**
   * Layout styles
   */
  groupCardGridSpacing: IStyleParam<StyleParamType.Number>;
  groupCardGridTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardGridSideSpacing: IStyleParam<StyleParamType.Number>;

  groupCardListTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListSideSpacing: IStyleParam<StyleParamType.Number>;
  sideBarHorizontalSpacing: IStyleParam<StyleParamType.Number>;
  sideBarVerticalSpacing: IStyleParam<StyleParamType.Number>;
  sideBarProportion: IStyleParam<StyleParamType.Number>;

  groupCardListWidgetSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListWidgetTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  groupCardListWidgetSideSpacing: IStyleParam<StyleParamType.Number>;

  cardSideBySideHeight: IStyleParam<StyleParamType.Number>;

  /**
   * Design styles
   */
  listTitleColor: IStyleParam<StyleParamType.Color>;
  listTitleFont: IStyleParam<StyleParamType.Font>;
  mobileListTitleFontSize: IStyleParam<StyleParamType.Number>;
  groupNameColor: IStyleParam<StyleParamType.Color>;
  groupNameFont: IStyleParam<StyleParamType.Font>;
  sidebarGroupNameColor: IStyleParam<StyleParamType.Color>;
  sidebarGroupNameFont: IStyleParam<StyleParamType.Font>;
  mobileGroupNameFontSize: IStyleParam<StyleParamType.Number>;
  groupNameColorOnHover: IStyleParam<StyleParamType.Color>;
  searchSortingColor: IStyleParam<StyleParamType.Color>;
  groupInfoColor: IStyleParam<StyleParamType.Color>;
  groupInfoFont: IStyleParam<StyleParamType.Font>;
  mobileGroupInfoFontSize: IStyleParam<StyleParamType.Number>;
  sidebarTitleFont: IStyleParam<StyleParamType.Font>;
  sidebarTitleColor: IStyleParam<StyleParamType.Color>;
  buttonColor: IStyleParam<StyleParamType.Color>;
  buttonBackground: IStyleParam<StyleParamType.Color>;
  buttonTextColor: IStyleParam<StyleParamType.Color>;
  buttonTextFont: IStyleParam<StyleParamType.Font>;
  mobileButtonTextFontSize: IStyleParam<StyleParamType.Number>;
  cardBackgroundColor: IStyleParam<StyleParamType.Color>;
  appBackgroundColor: IStyleParam<StyleParamType.Color>;
  cardBorderColor: IStyleParam<StyleParamType.Color>;
  cardBorderWidth: IStyleParam<StyleParamType.Number>;
  cardBorderCornerRadius: IStyleParam<StyleParamType.Number>;
  applyShadow: IStyleParam<StyleParamType.Boolean>;
  shadowAngle: IStyleParam<StyleParamType.Number>;
  shadowDistance: IStyleParam<StyleParamType.Number>;
  shadowBlur: IStyleParam<StyleParamType.Number>;
  shadowColor: IStyleParam<StyleParamType.Color>;

  /**
   * Display styles
   */
  showHeaderTitle: IStyleParam<StyleParamType.Boolean>;
  showSearchSorting: IStyleParam<StyleParamType.Boolean>;
  showMemberCount: IStyleParam<StyleParamType.Boolean>;
  showAdminBadge: IStyleParam<StyleParamType.Boolean>;
  showImage: IStyleParam<StyleParamType.Boolean>;
  showCreateGroupButton: IStyleParam<StyleParamType.Boolean>; // use in sidebar layout
  showGroupsToJoin: IStyleParam<StyleParamType.Boolean>;
};

export const groupsStylesParams: IGroupsStylesParams = {
  /**
   * Layout styles
   */
  groupCardGridSpacing: {
    key: 'groupCardGridSpacing',
    inheritDesktop: false,
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  groupCardGridTopBottomSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'groupCardGridTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardGridSideSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'groupCardGridSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardListTopBottomSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'groupCardListTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardListSideSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'groupCardListSideSpacing',
    type: StyleParamType.Number,
  },
  groupCardListWidgetSpacing: {
    getDefaultValue: () => 0,
    inheritDesktop: false,
    key: 'groupCardListSpacing',
    type: StyleParamType.Number,
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  groupCardListWidgetTopBottomSpacing: {
    getDefaultValue: () => 20,
    inheritDesktop: false,
    key: 'groupCardListTopBottomSpacing',
    type: StyleParamType.Number,
  },
  groupCardListWidgetSideSpacing: {
    getDefaultValue: () => 0,
    inheritDesktop: false,
    key: 'groupCardListSideSpacing',
    type: StyleParamType.Number,
  },
  cardSideBySideHeight: {
    getDefaultValue: () => 241,
    key: 'cardSideBySideHeight',
    type: StyleParamType.Number,
  },
  sideBarHorizontalSpacing: {
    getDefaultValue: () => 40,
    key: 'sideBarHorizontalSpacing',
    type: StyleParamType.Number,
  },
  sideBarVerticalSpacing: {
    getDefaultValue: () => 24,
    key: 'sideBarVerticalSpacing',
    type: StyleParamType.Number,
  },
  sideBarProportion: {
    getDefaultValue: () => 30,
    key: 'sideBarProportion',
    type: StyleParamType.Number,
  },

  /**
   * Design styles
   */
  listTitleColor: {
    key: 'listTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  listTitleFont: {
    key: 'listTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_1, {
      size: 24,
      htmlTag: 'h1',
    }),
  },
  mobileListTitleFontSize: {
    key: 'mobileListTitleFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  groupNameColor: {
    key: 'groupNameColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupNameFont: {
    key: 'groupNameFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_3, { size: 20 }),
  },
  sidebarGroupNameColor: {
    key: 'sidebarGroupNameColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  sidebarGroupNameFont: {
    key: 'sidebarGroupNameFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  mobileGroupNameFontSize: {
    key: 'mobileGroupNameFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 20,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  groupNameColorOnHover: {
    key: 'groupNameColorOnHover',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  searchSortingColor: {
    key: 'searchSortingColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupInfoColor: {
    key: 'groupInfoColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  groupInfoFont: {
    key: 'groupInfoFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, { size: 14 }),
  },
  mobileGroupInfoFontSize: {
    key: 'mobileGroupInfoFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 14,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  sidebarTitleFont: {
    key: 'sidebarTitleFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.HEADING_2, { size: 16 }),
  },
  sidebarTitleColor: {
    key: 'sidebarTitleColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5'),
  },
  buttonColor: {
    key: 'buttonColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonBackground: {
    key: 'buttonBackground',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  buttonTextColor: {
    key: 'buttonTextColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  buttonTextFont: {
    key: 'buttonTextFont',
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam(FontTheme.PARAGRAPH_2, {
      size: 16,
    }),
  },
  mobileButtonTextFontSize: {
    key: 'mobileButtonTextFontSize',
    type: StyleParamType.Number,
    getDefaultValue: () => 16,
    dangerousKeyTransformationOverride: doNotTransformSettingsKey,
  },
  cardBackgroundColor: {
    key: 'cardBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  appBackgroundColor: {
    key: 'appBackgroundColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1'),
  },
  cardBorderColor: {
    key: 'cardBorderColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.2),
  },
  cardBorderWidth: {
    key: 'cardBorderWidth',
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cardBorderCornerRadius: {
    key: 'cardBorderCornerRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  applyShadow: {
    key: 'applyShadow',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  shadowAngle: {
    key: 'shadowAngle',
    type: StyleParamType.Number,
    getDefaultValue: () => 180,
  },
  shadowDistance: {
    key: 'shadowDistance',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  shadowBlur: {
    key: 'shadowBlur',
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  shadowColor: {
    key: 'shadowColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  },

  /**
   * Display styles
   */
  showHeaderTitle: {
    key: 'showHeaderTitle',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showSearchSorting: {
    key: 'showSearchSorting',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showMemberCount: {
    key: 'showMemberCount',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showAdminBadge: {
    key: 'showAdminBadge',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showImage: {
    key: 'showImage',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  // use in sidebar layout
  showCreateGroupButton: {
    key: 'showCreateGroupButton',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
  showGroupsToJoin: {
    key: 'showGroupsToJoin',
    type: StyleParamType.Boolean,
    getDefaultValue: () => true,
  },
};
