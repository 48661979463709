import React from 'react';
import cls from 'classnames';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';
import { IconButton } from 'wui/IconButton';
import { ChevronRight as ChevronRightIcon } from '@wix/wix-ui-icons-common/on-stage';

import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { useSettings } from '@wix/tpa-settings/react';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import type { IGroup } from 'store/groups';

import { Box } from 'wui/Box';
import { ListItem } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { Button } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Typography } from 'wui/Typography';
import { Wire } from 'wui/Wire';

import { UISref, useRouter } from 'router/react';
import { GroupInfo } from 'common/components/GroupInfo';
import { RoleIcon } from 'common/components/RoleIcon';

import { useBiParams } from 'common/hooks/useBiParams';

import { GroupListItemImage } from './GroupListItemImage';
import { GROUP_LIST_ITEM, GROUP_LIST_ITEM_TITLE } from '../dataHooks';

import classes from './GroupListItem.scss';

interface IGroupListItemProps {
  group: IGroup;
}

export function JoinedGroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const router = useRouter();
  const settings = useSettings();

  const biParams = useBiParams();

  if (isMobile) {
    return (
      <ListItem disablePadding>
        <ListItemButton
          onClick={() => handleGroupClick('click_on_group')}
          className={cls(classes.root, classes.mobile)}
        >
          <Wire
            cssVarName="showImage--flex"
            legacyFallback={settings.get(settingsParams.showImage)}
          >
            <GroupListItemImage group={group} />
          </Wire>

          <ListItemText
            title={
              <Box verticalAlign="middle">
                <Typography
                  variant="p2-14"
                  noWrap
                  className={cls(classes.title, classes.mobile)}
                >
                  {group.name}
                </Typography>
                <Wire
                  cssVarName="showAdminBadge--inline"
                  legacyFallback={settings.get(settingsParams.showAdminBadge)}
                >
                  <RoleIcon role={group.role} />
                </Wire>
              </Box>
            }
            subtitleProps={{ noWrap: true }}
            subtitle={<GroupInfo wired misc groupId={group.id as string} />}
          />
          <ListItemAction>
            <IconButton tabIndex={-1} icon={<ChevronRightIcon />} />
          </ListItemAction>
        </ListItemButton>
      </ListItem>
    );
  }

  return (
    <ListItem
      className={classes.root}
      disablePadding
      data-hook={GROUP_LIST_ITEM}
    >
      <Wire
        cssVarName="showImage--inline"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsLivesiteClick({
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'click_on_group',
          })}
        >
          <a tabIndex={-1}>
            <GroupListItemImage group={group} />
          </a>
        </UISref>
      </Wire>

      <ListItemText
        title={
          <Box verticalAlign="middle">
            <Typography variant="p2-16" noWrap className={classes.title}>
              <UISref
                state="group"
                params={{ slug: group.slug }}
                bi={groupsLivesiteClick({
                  screen_name: biParams.groupsScreenWithTab(),
                  button_name: 'click_on_group',
                })}
              >
                <TextButton
                  as="a"
                  variant="secondary"
                  data-hook={GROUP_LIST_ITEM_TITLE}
                >
                  {group.name}
                </TextButton>
              </UISref>
            </Typography>

            <Wire
              cssVarName="showAdminBadge--inline"
              legacyFallback={settings.get(settingsParams.showAdminBadge)}
            >
              <RoleIcon role={group.role} />
            </Wire>
          </Box>
        }
        subtitle={<GroupInfo wired groupId={group.id as string} />}
      />

      <Wire
        cssVarName="showButton--flex"
        legacyFallback={settings.get(groupsListWidgetSettingsParams.showButton)}
      >
        <ListItemAction>
          <Button outlined onClick={() => handleGroupClick('view_group')}>
            {t('groups-web.btn.view-group')}
          </Button>
        </ListItemAction>
      </Wire>
    </ListItem>
  );

  function handleGroupClick(biOrigin: string) {
    return router.go('group', { slug: group.slug });
  }
}

JoinedGroupListItem.displayName = 'JoinedGroupListItem';
