import React from 'react';
import {
  ErrorMonitorBoundary,
  useTranslation,
  useEnvironment,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { PortalProvider } from 'wui/Portal/context';
import { ErrorState } from 'wui/ErrorState';

import { Router } from 'router/react';
import { ControllerProvider } from 'common/context/controller';
import { ThemeProvider } from 'common/context/theme';
import type { IVMProps } from 'vm/types';

import { Toasts } from '../Toasts';
import { GroupMembershipDialogs } from '../GroupMembership';

interface ISocialGroupsAppProps extends WidgetProps<IVMProps> {
  children: React.ReactNode;
}

export function SocialGroupsApp(props: ISocialGroupsAppProps) {
  const { children, store, ...rest } = props;
  const { t } = useTranslation();
  const { isRTL } = useEnvironment();

  return (
    <div data-hook="social-groups-app" dir={isRTL ? 'rtl' : 'ltr'}>
      <ErrorMonitorBoundary
        isDebug
        fallback={
          <ErrorState
            title={t('groups-web.error-state.title')}
            subtitle={t('groups-web.error-state.subtitle')}
            actionLabel={t('groups-web.error-state.retry.label')}
          />
        }
      >
        <SocialGroupStore state={store}>
          <Router>
            <ThemeProvider host={props.host}>
              <PortalProvider host={props.host}>
                <ControllerProvider {...rest}>
                  <WixCommentsApiProvider {...rest}>
                    {children}
                    <Toasts />
                    <GroupMembershipDialogs />
                  </WixCommentsApiProvider>
                </ControllerProvider>
              </PortalProvider>
            </ThemeProvider>
          </Router>
        </SocialGroupStore>
      </ErrorMonitorBoundary>
    </div>
  );
}

SocialGroupsApp.displayName = 'SocialGroupsApp';
