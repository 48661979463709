import { IGroupsSettingsParams, groupsSettingsParams } from 'settings/groups';
import { IFeedSettingsParams, feedSettingsParams } from 'settings/feed';
import {
  IAppearanceSettingsParams,
  appearanceSettingsParams,
} from 'settings/appearance';

export interface IGroupsPageSettings
  extends IGroupsSettingsParams,
    IFeedSettingsParams,
    IAppearanceSettingsParams {}

const settingsParams: IGroupsPageSettings = {
  ...appearanceSettingsParams,
  ...groupsSettingsParams,
  ...feedSettingsParams,
};

export default settingsParams;
