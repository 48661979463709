import React from 'react';
import cls from 'classnames';
import {
  TextButton as TextButtonTPA,
  TextButtonPriority,
  TextButtonProps,
} from 'wix-ui-tpa/cssVars';

import {
  OverridableComponent,
  OverridableComponentProps,
} from '../OverridableComponent';

import classes from './TextButton.scss';

interface IProps extends Omit<TextButtonProps, 'as' | 'priority'> {
  // wired to user settings
  wired?: boolean;
  variant?: 'primary' | 'secondary' | 'link';
}

// eslint-disable-next-line react/display-name
export const TextButton = React.forwardRef(
  (props: OverridableComponentProps<IProps, typeof TextButtonTPA>, ref) => {
    const { variant, wired, className, ...rest } = props;

    return (
      <TextButtonTPA
        ref={ref}
        className={cls(className, {
          [classes.wired]: wired,
        })}
        priority={variant as TextButtonPriority}
        {...rest}
      />
    );
  },
) as OverridableComponent<IProps, typeof TextButtonTPA>;

TextButton.displayName = 'wui/TextButton';
TextButton.defaultProps = {
  wired: false,
  variant: 'primary',
};
