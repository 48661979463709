import { cloneDeep, curryRight } from 'lodash';
import { createSelector } from 'reselect';
import { AuthorizedGroupCreators } from '@wix/ambassador-social-groups-v2-groups-app-settings/types';

import type { IApplicationDialog } from './types';
import type { IRootState } from '../types';

export const selectCurrentUser = createSelector(
  (state: IRootState) => state.application.user,
  (user) => user,
);

export const selectIsSiteAdmin = createSelector(
  (state: IRootState) => state.application.user,
  (user) => user.role === 'Admin',
);

export const selectIsLoggedIn = createSelector(
  (state: IRootState) => state.application.user,
  (user) => user.loggedIn,
);

export const selectStateDeclarations = createSelector(
  (state: IRootState) => state.application.router.states,
  (states) => cloneDeep(states),
);

export const selectUserToken = createSelector(
  (state: IRootState) => state.application.user.instance as string,
  (token) => token,
);

export const selectDialogs = createSelector(
  (state: IRootState) => state.application.dialogs,
  (dialogs) => dialogs,
);

export const selectAppData = createSelector(
  (state: IRootState) => state.application.appData,
  (appData) => appData,
);

export const selectMetaSiteId = createSelector(
  selectAppData,
  (data) => data.metaSiteId,
);

export const selectDashboardUrl = createSelector(
  selectMetaSiteId,
  (metaSiteId) =>
    `https://manage.wix.com/dashboard/${metaSiteId}/social-groups-dashboard`,
);

export const selectFollowStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.application.status,
      (_: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status.follow[memberId] || {},
  ),
  2,
);

export const selectShareProfileConsent = curryRight(
  createSelector(
    [
      (state: IRootState) => state.application,
      (_: IRootState, groupId: string) => groupId,
    ],
    (application, groupId) =>
      !application.user.isPrivate ||
      application.shareProfileConsent[groupId] ||
      false,
  ),
  2,
);

export const selectDialog = curryRight(
  createSelector(
    [selectDialogs, (_, dialog: IApplicationDialog) => dialog],
    (dialogs, dialog) => dialogs[dialog],
  ),
  2,
);

export const selectCanCreateGroup = createSelector(
  (state: IRootState) => state.application.settings,
  selectIsSiteAdmin,
  selectIsLoggedIn,
  (settings, isSiteAdmin, isLoggedIn) => {
    switch (settings.authorizedGroupCreators) {
      case AuthorizedGroupCreators.OWNER:
        return isSiteAdmin;

      case AuthorizedGroupCreators.MEMBERS:
        return isSiteAdmin || isLoggedIn;

      case AuthorizedGroupCreators.MEMBERS_WITH_APPROVAL:
      case AuthorizedGroupCreators.UNKNOWN_POLICY:
      default:
        return isSiteAdmin;
    }
  },
);

export const selectCanCreateGroupRequests = createSelector(
  (state: IRootState) => state.application.settings,
  selectIsSiteAdmin,
  selectIsLoggedIn,
  (settings, isSiteAdmin, isLoggedIn) => {
    switch (settings.authorizedGroupCreators) {
      case AuthorizedGroupCreators.MEMBERS_WITH_APPROVAL:
        return !isSiteAdmin && isLoggedIn;

      default:
        return false;
    }
  },
);
