import React from 'react';

import { Image } from '@wix/ambassador-social-groups-v2-group/types';

import { Media } from 'wui/Media';

import { ImageRatio } from 'settings/consts';

interface GroupImageProps
  extends Omit<React.ComponentProps<typeof Media>, 'src' | 'aspectRatio'> {
  image?: Image;
  aspectRatio?: ImageRatio;
}

export function GroupImage({ image, aspectRatio, ...rest }: GroupImageProps) {
  return (
    <Media
      src={(image?.mediaId as string) || (image?.fileUrl as string)}
      sourceHeight={image?.height as number}
      sourceWidth={image?.width as number}
      aspectRatio={toAspectRatio(aspectRatio)}
      {...rest}
    />
  );

  function toAspectRatio(aspectRatio?: ImageRatio) {
    if (!aspectRatio) {
      return;
    }
    return aspectRatio === ImageRatio.rectangle ? 'cinema' : 'square';
  }
}

GroupImage.displayName = 'GroupImage';
