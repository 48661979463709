import React, { cloneElement } from 'react';

import type { LogObject } from '@wix/bi-logger-groups/v2/types';
import { useBi, useEnvironment } from '@wix/yoshi-flow-editor';
import type { RawParams, HrefOptions } from '@uirouter/core';

import { useHref, useRouter } from '../hooks';

interface IUISrefProps {
  state: string;
  params?: RawParams;
  options?: HrefOptions;
  disabled?: boolean;
  bi?: LogObject<any>;

  children: React.ReactElement;
}

export function UISref(props: IUISrefProps) {
  const {
    state,
    params,
    options,
    disabled,
    children,
    bi: biProps,
    ...rest
  } = props;
  const { isViewer } = useEnvironment();
  const bi = useBi();

  const router = useRouter();
  const href = useHref(state, params, options);

  if (disabled) {
    return cloneElement(children, rest);
  }

  return cloneElement(children, {
    href: isViewer ? href : undefined,
    onClick: handleClick,
    ...children.props,
    ...rest,
  });

  function handleClick(event: React.MouseEvent) {
    if (biProps) {
      bi.report(biProps);
    }

    // viewer is using [href]
    if (isViewer) {
      return;
    }

    handleNavigation(event);
  }

  function handleNavigation(event: React.MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    router.go(state, params, options);
  }
}

UISref.defaultProps = {
  options: {
    absolute: true,
  },
};

UISref.displayName = 'UISref';
