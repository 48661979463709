import { ISettingsParam } from '@wix/tpa-settings';
import { Alignment } from '../consts';

export type IFeedSettingsParams = {
  showCreatePost: ISettingsParam<boolean>;
  showFeedTitle: ISettingsParam<boolean>;
  /**
   * ❗️the option couldn't be migrated to `styleParams`
   * because `styleParams` aren't accessible in `CreateControllerFn`
   *
   * Depending on the value we decide to fetch feed and comments or not
   *
   * Always fetch feed (not relying on the option) will cost approximately 900-1300ms,
   * and on `pageReady` after SSR +800ms for fetching comments
   *
   * Looks like `showFeed` should be a "global" option and dosn't rely on css breakpoints.
   */
  showFeed: ISettingsParam<boolean>;
  feedTitle: ISettingsParam<string | null>;
  feedAlignment: ISettingsParam<Alignment>;
};

export const feedSettingsParams: IFeedSettingsParams = {
  feedTitle: {
    key: 'feedTitle',
    getDefaultValue: ({ t }) =>
      t('groups-web.settings.TextTab.feedTitle.default'),
  },
  showCreatePost: {
    getDefaultValue: () => true,
    key: 'showCreatePost',
  },
  showFeedTitle: {
    getDefaultValue: () => true,
    key: 'showFeedTitle',
  },
  // using in List/Grid layout
  showFeed: {
    getDefaultValue: () => false,
    key: 'showGroupsToJoin',
  },
  feedAlignment: {
    getDefaultValue: () => Alignment.center,
    key: 'feedAlignment',
  },
};
