import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UIRouter } from '@uirouter/core';

import type { IRootState } from 'store/types';

import { selectStateDeclarations } from 'store/selectors';

import { viewerPlugin } from '../../viewer-plugin';
import { RouterContext } from '../context';

interface IRouterProps {
  children: React.ReactNode;
}

export function Router(props: IRouterProps) {
  const { children } = props;

  const { baseUrl, url } = useSelector(
    (state: IRootState) => state.application.router,
  );

  const states = useSelector(selectStateDeclarations);

  const router = useMemo(() => {
    const router = new UIRouter();

    router.plugin(
      viewerPlugin({
        url,
        baseUrl,
      }),
    );

    Object.values(states).forEach((state) =>
      router.stateRegistry.register(state),
    );

    return router;
  }, []);

  useEffect(() => {
    return () => router.dispose();
  }, []);

  return (
    <RouterContext.Provider value={router}>{children}</RouterContext.Provider>
  );
}

Router.displayName = 'Router';
