import { ISettingsParam } from '@wix/tpa-settings';
import { transformSettingsKey } from '../helpers';
import {
  Alignment,
  COMPONENT,
  DEFAULT_GRID_CARD_SPACING,
  GroupsRequestSort,
  ImageRatio,
  LayoutType,
} from '../consts';

export type IGroupsSettingsParams = {
  groupListTitle: ISettingsParam<string>;
  groupMembersLabel: ISettingsParam<string>;

  showHeaderTitle: ISettingsParam<boolean>;
  showSearchSorting: ISettingsParam<boolean>;
  showMemberCount: ISettingsParam<boolean>;
  showAdminBadge: ISettingsParam<boolean>;
  showImage: ISettingsParam<boolean>;
  showCreateGroupButton: ISettingsParam<boolean>; // use in sidebar layout
  showGroupsToJoin: ISettingsParam<boolean>;

  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;

  // use only in sidebar, before the refactoring was in list and grid as well
  headerAlignment: ISettingsParam<Alignment>;

  sideBarAlignment: ISettingsParam<Alignment>;

  sortBy: ISettingsParam<GroupsRequestSort>;
};

export const groupsSettingsParams: IGroupsSettingsParams = {
  /**
   * Text settings
   */
  groupListTitle: {
    getDefaultValue: ({ t }) => t('groups-web.settings.TextTab.groups.default'),
    key: 'groupListTitle',
  },
  groupMembersLabel: {
    getDefaultValue: () => 'Members',
    key: 'groupMembersLabel',
  },

  /**
   * Display settings
   */
  showHeaderTitle: {
    getDefaultValue: () => true,
    key: 'showHeaderTitle',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showSearchSorting: {
    getDefaultValue: () => true,
    key: 'showSearchSorting',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showMemberCount: {
    getDefaultValue: () => true,
    key: 'showMemberCount',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showAdminBadge: {
    getDefaultValue: () => true,
    key: 'showAdminBadge',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  showImage: {
    getDefaultValue: () => true,
    key: 'showImage',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  // use in sidebar layout
  showCreateGroupButton: {
    getDefaultValue: () => true,
    key: 'showCreateGroupButton',
  },
  showGroupsToJoin: {
    getDefaultValue: () => true,
    key: 'showGroupsToJoin',
  },

  /**
   * Layout settings
   */
  groupListLayout: {
    getDefaultValue: () => {
      return getDefaultGroupListLayout();
    },
    key: 'groupListLayout',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  headerAlignment: {
    getDefaultValue: () => Alignment.left,
    key: 'headerAlignment',
  },
  sideBarAlignment: {
    getDefaultValue: () => Alignment.right,
    key: 'sideBarAlignment',
  },
  imageRatio: {
    getDefaultValue: ({ getSettingParamValue }) => {
      // https://github.com/wix-private/tpa-settings#10-dependent-default-values
      // Important note, that getSettingParamValue(groupsSettingsParams.groupListLayout) will not get the groupListLayout default value.
      const layout =
        getSettingParamValue(groupsSettingsParams.groupListLayout) ||
        getDefaultGroupListLayout();
      if (layout === LayoutType.listWidget) {
        return ImageRatio.square;
      }

      return ImageRatio.rectangle;
    },
    key: 'imageRatio',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },
  gridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'gridCardSpacing',
    dangerousKeyTransformationOverride: transformSettingsKey,
  },

  /**
   * Settings settings
   */
  sortBy: {
    getDefaultValue: () => GroupsRequestSort.RECENT_ACTIVITY,
    key: 'sortBy',
  },
};

function getDefaultGroupListLayout() {
  switch (COMPONENT.name) {
    case 'GroupListWidget':
      return LayoutType.listWidget;
    case 'SideBySideWidget':
      return LayoutType.sideBySide;
    default:
      return LayoutType.list;
  }
}
