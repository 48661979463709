import { useSelector } from 'react-redux';

import type { IRootState } from 'store/types';

export function useCurrentStateAndParams() {
  const { state, params, states } = useSelector(
    (state: IRootState) => state.application.router,
  );

  return {
    params,
    state: states[state],
  };
}
