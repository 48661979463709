import React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { ImageCrop, ImageRatio, TextAlignment } from 'settings/consts';
import { groupsSettingsParams as settingsParams } from 'settings/groups';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';

import { IGroup } from 'store/groups';

import { Card } from 'wui/Card';
import { CardContent } from 'wui/CardContent';
import { Show } from 'wui/Show';
import { Wire } from 'wui/Wire';
import { Box } from 'wui/Box';
import { Alignment } from 'wui/Box/types';

import { Link } from 'common/components/Link';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupImage } from 'common/components/GroupImage';
import { RoleIcon } from 'common/components/RoleIcon';
import { useBiParams } from 'common/hooks/useBiParams';
import { UISref } from 'router/react';

import { GroupGridItemAction } from 'Groups/Widget/Layout/GridLayout/GroupGrid/GroupGridItem/GroupGridItemAction';
import widgetSettingsParams from 'GroupsListWidget/settingsParams';

import { getDirection } from './helpers';

import classes from './GroupStripeItem.scss';

interface IGroupStripeItemProps {
  group: IGroup;
  even?: boolean;
}

const textAlignmentsToBoxAlignment: { [key in TextAlignment]: Alignment } = {
  [TextAlignment.Left]: 'left',
  [TextAlignment.Center]: 'center',
  [TextAlignment.Right]: 'right',
};

export function GroupStripeItem({ group, even }: IGroupStripeItemProps) {
  const { isMobile } = useEnvironment();
  const biParams = useBiParams();

  const settings = useSettings();

  const canCrop =
    settings.get(widgetSettingsParams.imageCrop) === ImageCrop.CROP;
  const alignment =
    textAlignmentsToBoxAlignment[
      settings.get(widgetSettingsParams.textAlignment) as TextAlignment
    ];

  return (
    <Card
      gap="SP1"
      padding="SP0"
      direction={getDirection(
        settings.get(widgetSettingsParams.imageLayout),
        even,
      )}
      height="240px"
    >
      <Wire
        cssVarName="showImage--inline"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsLivesiteClick({
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'click_on_group',
          })}
        >
          <a className={classes.image}>
            <GroupImage
              fluid
              aspectRatio={ImageRatio.rectangle}
              image={group.coverImage?.image}
              resize={canCrop ? 'cover' : 'contain'}
            />
          </a>
        </UISref>
      </Wire>

      <CardContent verticalAlign="middle" align={alignment}>
        <Box align={alignment} verticalAlign="middle">
          <Link
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            {group.name}
          </Link>
          <Show if={!isMobile}>
            <RoleIcon role={group.role} />
          </Show>
        </Box>

        <GroupInfo wired groupId={group.id as string} align={alignment} />

        <Wire
          cssVarName="showButton--inline"
          legacyFallback={settings.get(
            groupsListWidgetSettingsParams.showButton,
          )}
        >
          <GroupGridItemAction group={group} fullWidth={false} />
        </Wire>
      </CardContent>
    </Card>
  );
}

GroupStripeItem.displayName = 'GroupStripeItem';
