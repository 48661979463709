import React from 'react';
import cls from 'classnames';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { TextButton } from 'wui/TextButton';

import { groupsLivesiteClick } from '@wix/bi-logger-groups/v2';

import { useSettings } from '@wix/tpa-settings/react';
import { groupsListWidgetSettingsParams } from 'settings/groups-list-widget';
import { groupsSettingsParams as settingsParams } from 'settings/groups/settings';

import type { IGroup } from 'store/groups';

import { ListItem } from 'wui/ListItem';
import { ButtonSize } from 'wui/Button';
import { ListItemText } from 'wui/ListItemText';
import { ListItemAction } from 'wui/ListItemAction';
import { Wire } from 'wui/Wire';

import { UISref } from 'router/react';
import { GroupInfo } from 'common/components/GroupInfo';
import { GroupMembershipButton } from 'common/components/GroupMembership';
import { useBiParams } from 'common/hooks/useBiParams';

import { GroupListItemImage } from './GroupListItemImage';

import { GROUP_LIST_ITEM, GROUP_LIST_ITEM_TITLE } from '../dataHooks';

import classes from './GroupListItem.scss';

interface IGroupListItemProps {
  group: IGroup;
}

export function SuggestedGroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const biParams = useBiParams();

  return (
    <ListItem
      disablePadding
      data-hook={GROUP_LIST_ITEM}
      className={cls(classes.root, { [classes.mobile]: isMobile })}
    >
      <Wire
        cssVarName="showImage--inline"
        legacyFallback={settings.get(settingsParams.showImage)}
      >
        <UISref
          state="group"
          params={{ slug: group.slug }}
          bi={groupsLivesiteClick({
            screen_name: biParams.groupsScreenWithTab(),
            button_name: 'click_on_group',
          })}
        >
          <a tabIndex={-1}>
            <GroupListItemImage group={group} />
          </a>
        </UISref>
      </Wire>

      <ListItemText
        title={
          <UISref
            state="group"
            params={{ slug: group.slug }}
            bi={groupsLivesiteClick({
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'click_on_group',
            })}
          >
            <TextButton
              as="a"
              variant="secondary"
              data-hook={GROUP_LIST_ITEM_TITLE}
            >
              {group.name}
            </TextButton>
          </UISref>
        }
        subtitle={<GroupInfo wired groupId={group.id as string} />}
        titleProps={{
          noWrap: true,
          className: cls(classes.title, { [classes.mobile]: isMobile }),
        }}
        subtitleProps={{ noWrap: true }}
      />

      <Wire
        cssVarName="showButton--flex"
        legacyFallback={settings.get(groupsListWidgetSettingsParams.showButton)}
      >
        <ListItemAction>
          <GroupMembershipButton
            upgrade={!isMobile}
            size={isMobile ? ButtonSize.tiny : undefined}
            groupId={group.id as string}
            bi={groupsLivesiteClick({
              group_id: group.id as string,
              screen_name: biParams.groupsScreenWithTab(),
              button_name: 'join',
            })}
          />
        </ListItemAction>
      </Wire>
    </ListItem>
  );
}

SuggestedGroupListItem.displayName = 'SuggestedGroupListItem';
