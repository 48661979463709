export const GROUPS_WIDGET_ID = 'a7dcdfcb-8abd-4008-af19-fed5fcd12b40';
export const GROUPS_APP_ID = '148c2287-c669-d849-d153-463c7486a694';

export const MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';
export const PAID_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const INVITE_PAID_PLANS = 'invite_paid_plans';

// Should be in sync with dev center page id
export const GROUPS_MEMBERS_AREA_PAGE_ID = 'groups_area';

// page Id from https://dev.wix.com/
export const GROUPS_PAGE_ID = 'groups';
export const GROUP_PAGE_ID = 'group';
export const GROUP_PAGES = [GROUP_PAGE_ID, GROUPS_PAGE_ID];

export const PAGE_TITLES = {
  [GROUP_PAGE_ID]: 'groups-web.initial.page-name.group-page',
  [GROUPS_MEMBERS_AREA_PAGE_ID]:
    'groups-web.initial.page-name.groups-members-area-page',
  [GROUPS_PAGE_ID]: 'groups-web.initial.page-name.groups-page',
};

export const GROUPS_DASHBOARD_URL = 'social-groups-dashboard';

export const WIX_STATIC = 'https://static.wixstatic.com';
export const WIX_STATIC_VIDEO_BASE_URL = 'https://video.wixstatic.com/video';
