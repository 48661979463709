import { ParamTypeDefinition } from '@uirouter/core';

/**
 *  The encoding used by default is based on a very early version of the general URI percent-encoding rules,
 *  with a number of modifications such as newline normalization and replacing spaces with "+" instead of "%20"
 *
 *  debug note: try to search group by typing query with a space
 *  TODO: add test for it.
 */
export const URIEncoded: ParamTypeDefinition = {
  encode: encodeURIComponent,
  decode: decodeURIComponent,
  equals: (a: any, b: any) => a === b,
  is: () => true,
};
