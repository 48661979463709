import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';

import { useEnvironment } from '@wix/yoshi-flow-editor';

import { groupsSettingsParams } from 'settings/groups/settings';
import { useSettings } from '@wix/tpa-settings/react';

import type { IGroup } from 'store/groups';
import { selectIsJoinedGroupMember } from 'store/selectors';

import { ListItem } from 'wui/ListItem';
import { ListItemText } from 'wui/ListItemText';
import { Skeleton } from 'wui/Skeleton';
import { Wire } from 'wui/Wire';

import { GroupListItemImageSkeleton } from './GroupListItemImage';
import { JoinedGroupListItem } from './JoinedGroupListItem';
import { SuggestedGroupListItem } from './SuggestedGroupListItem';

import classes from './GroupListItem.scss';

interface IGroupListItemProps {
  group: IGroup;
}

export function GroupListItem(props: IGroupListItemProps) {
  const { group } = props;

  const isJoined = useSelector(selectIsJoinedGroupMember(group.id as string));

  if (isJoined) {
    return <JoinedGroupListItem group={group} />;
  }

  return <SuggestedGroupListItem group={group} />;
}

GroupListItem.displayName = 'GroupListItem';

export function GroupListItemSkeleton() {
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  return (
    <ListItem
      disablePadding
      className={cls(classes.root, { [classes.mobile]: isMobile })}
    >
      <Wire
        cssVarName="showImage--flex"
        legacyFallback={settings.get(groupsSettingsParams.showImage)}
      >
        <GroupListItemImageSkeleton />
      </Wire>

      <ListItemText
        title={
          <Skeleton
            className={cls(classes.title, { [classes.mobile]: isMobile })}
            width={isMobile ? '50%' : '40%'}
          />
        }
        subtitle={
          <Skeleton
            className={cls(classes.info, { [classes.info]: isMobile })}
            width={isMobile ? '80%' : '30%'}
          />
        }
      />
    </ListItem>
  );
}

GroupListItemSkeleton.displayName = 'GroupListItemSkeleton';
