import { IStyleParam, StyleParamType, wixColorParam } from '@wix/tpa-settings';

import { COMPONENT } from '../consts';

export type IAppearanceStylesParams = {
  cardBorderCornerRadius: IStyleParam<StyleParamType.Number>;
  applyShadow: IStyleParam<StyleParamType.Boolean>;
  shadowAngle: IStyleParam<StyleParamType.Number>;
  shadowDistance: IStyleParam<StyleParamType.Number>;
  shadowBlur: IStyleParam<StyleParamType.Number>;
  shadowColor: IStyleParam<StyleParamType.Color>;
  buttonBorderRadius: IStyleParam<StyleParamType.Number>;
};

export const appearanceStylesParams: IAppearanceStylesParams = {
  cardBorderCornerRadius: {
    key: 'cardBorderCornerRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  applyShadow: {
    key: 'applyShadow',
    type: StyleParamType.Boolean,
    getDefaultValue: () => false,
  },
  shadowAngle: {
    key: 'shadowAngle',
    type: StyleParamType.Number,
    getDefaultValue: () => 180,
  },
  shadowDistance: {
    key: 'shadowDistance',
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  shadowBlur: {
    key: 'shadowBlur',
    type: StyleParamType.Number,
    getDefaultValue: () => 10,
  },
  shadowColor: {
    key: 'shadowColor',
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-5', 0.1),
  },
  buttonBorderRadius: {
    key: 'buttonBorderRadius',
    type: StyleParamType.Number,
    getDefaultValue: () => {
      switch (COMPONENT.name) {
        case 'GroupPage':
          return 0;
        default:
          return 100;
      }
    },
  },
};
