import React, { HTMLAttributeAnchorTarget } from 'react';

import { UISref } from 'router/react';
import { TextButton } from 'wui/TextButton';

interface IProps
  extends React.ComponentProps<typeof TextButton>,
    Omit<React.ComponentProps<typeof UISref>, 'children'> {
  target?: HTMLAttributeAnchorTarget | undefined;

  srefProps?: Partial<React.ComponentProps<typeof UISref>>;
}

export function Link(props: IProps) {
  const { state, params, options, disabled, bi, ...rest } = props;

  return (
    <UISref
      bi={bi}
      state={state}
      params={params}
      options={options}
      disabled={disabled}
    >
      <TextButton as="a" rel="noreferrer" disabled={disabled} {...rest} />
    </UISref>
  );
}

Link.displayName = 'Link';
Link.defaultProps = { variant: 'secondary' };
