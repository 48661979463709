import { LocationConfig, UIRouter } from '@uirouter/core';

import type { ILocationConfig } from './types';

export class WixLocationConfig implements LocationConfig {
  constructor(protected location: ILocationConfig) {}

  private getNormalizedUrl() {
    return this.location.url.replace(/([^:]\/)\/+/g, '$1');
  }

  pathname() {
    const { pathname } = new URL(this.getNormalizedUrl());

    return decodeURIComponent(pathname);
  }

  search() {
    const { search } = new URL(this.getNormalizedUrl());

    return decodeURIComponent(search);
  }

  hash() {
    const { hash } = new URL(this.getNormalizedUrl());

    return decodeURIComponent(hash);
  }

  url() {
    return decodeURI(this.getNormalizedUrl());
  }

  port() {
    const url = new URL(this.getNormalizedUrl());

    if (url.port) {
      return Number(url.port);
    }

    return this.protocol() === 'https' ? 443 : 80;
  }

  protocol() {
    const url = new URL(this.getNormalizedUrl());

    return url.protocol.substring(0, url.protocol.length - 1);
  }

  host() {
    const url = new URL(this.getNormalizedUrl());

    return url.host;
  }

  baseHref() {
    const url = new URL(this.location.baseUrl);
    const pathname = decodeURIComponent(url.pathname);

    return pathname.endsWith('/') ? pathname : pathname + '/';
  }

  html5Mode() {
    return true;
  }

  hashPrefix(newprefix?: string | undefined) {
    return '';
  }

  dispose(router?: UIRouter | undefined) {}
}
