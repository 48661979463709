import {
  UIRouter,
  LocationServices,
  stripLastPathElement,
} from '@uirouter/core';

import type { WixLocationConfig } from './WixLocationConfig';

export class ControllerLocationService implements LocationServices {
  constructor(protected router: UIRouter) {}

  url(url?: string) {
    const config = this.router.locationConfig as WixLocationConfig;

    const pathname = config.pathname();
    const hash = config.hash();
    const search = config.search();

    const baseUrl = stripLastPathElement(config.baseHref());

    if (url) {
      throw new Error('UI.Router navigation is not supported inside Viewer');
    }

    return pathname.substring(baseUrl.length) + search + hash;
  }

  path() {
    const config = this.router.locationConfig as WixLocationConfig;
    const baseUrl = stripLastPathElement(config.baseHref());
    const pathname = config.pathname();

    return pathname.substring(baseUrl.length);
  }

  search() {
    const config = this.router.locationConfig as WixLocationConfig;
    const { searchParams } = new URL(config.url());

    return Object.fromEntries(searchParams.entries());
  }

  hash() {
    const config = this.router.locationConfig as WixLocationConfig;
    const hash = config.hash();

    return hash.substring(1);
  }

  onChange(callback: EventListener) {
    return () => {};
  }

  dispose(router?: UIRouter | undefined) {}
}
