import React from 'react';
import type { HrefOptions, RawParams } from '@uirouter/core';

import { useRouter } from './useRouter';
import { useCurrentStateAndParams } from './useCurrentState';

export function useHref(
  state: string,
  params: RawParams = {},
  options: HrefOptions = {},
) {
  const router = useRouter();
  const { params: currentParams } = useCurrentStateAndParams();
  const { inherit = true } = options;

  const href = React.useMemo(() => {
    return router.href(
      state,
      {
        ...(inherit ? currentParams : {}),
        ...params,
      },
      options,
    );
  }, [
    state,
    JSON.stringify(params),
    JSON.stringify(options),
    JSON.stringify(currentParams),
  ]);

  return href;
}
