import { ISettingsParam } from '@wix/tpa-settings';

import { ButtonType, COMPONENT } from '../consts';

export type IAppearanceSettingsParams = {
  buttonType: ISettingsParam<ButtonType>;
};

export const appearanceSettingsParams: IAppearanceSettingsParams = {
  buttonType: {
    getDefaultValue: () => {
      switch (COMPONENT.name) {
        case 'GroupPage':
          return ButtonType.rounded;
        default:
          return ButtonType.rectangle;
      }
    },
    key: 'buttonType',
  },
};
